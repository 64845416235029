import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQ from "../../utils"
import questions from "../../automationFaq.json"
function Automationtesting() {
  return (
    <Layout>
      <SEO
        title="Automation Testing Company in Delhi NCR, India, USA & UK"
        description="Automation Testing is one of the core services offered by D2i. D2i is one of the leading company in India specializing in Automation Testing."
      />
      <div className="text-justify">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h1>Automation Testing Company</h1>
              </div>
              <p>
                Software Testing services is a community of software testers who
                are passionate about quality and love to test.&nbsp;
              </p>
              <p>
                Software Test automation makes use of specialized tools to
                control the execution of tests and compares the actual results
                against the expected result. Usually regression tests, which are
                repetitive actions, are automated.
              </p>
              <p>
                Testing Tools not only help us to perform regression tests but
                also helps us to automate data set up generation, product
                installation, GUI interaction, defect logging, etc.
              </p>
              <p>
                We are an independent software testing and test automation
                services company. We have helped development teams deliver best
                quality software on time and provide a wide range of Software
                Testing services.
              </p>
              <p>
                Our testers also provide comprehensive test summary reports and
                recommendations for continuous quality improvement.
              </p>
              <p className="mb-2">
                D2i Technology basically provides support for below Automation
                things:
              </p>
              <ul className="mb-4">
                <li>DevOps</li>
                <li>Jenkins</li>
                <li>Automation of Job pipelines</li>
                <li>Selenium Webdriver Automation</li>
                <li>Jenkins integration for ongoing projects</li>
                <li>Appium (Mobile Automation Android &amp; iOS App's)</li>
                <li>Docker</li>
                <li>Webdriver I/O</li>
                <li>Katalon</li>
                <li>Protractor</li>
              </ul>
              <div className="FAQ">{FAQ(questions)}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Automationtesting
